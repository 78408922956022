export function getTagRelations<T extends ModelName>(
	models: T[],
): ['tag', T][] {
	return models.map((model) => ['tag', model])
}

// should be in camelCase
export const modelNames = [
	'customer',
	'customerTeam',
	'customerLoginAllow',
	'event',
	'feature',
	'healthScore',
	'industry',
	'material',
	'materialType',
	'metric',
	'metricValue',
	'objRole',
	'persona',
	'project',
	'role',
	'useCase',
	'useCaseType',
	'tag',
	'task',
	'token',
	'training',
	'link',
	// 'userEngagement',
] as const

// export const inPlaceAddModels = [
// 	'customer',
// 	'material',

// ] as const satisfies ModelName[]

export type ModelName = (typeof modelNames)[number]

export const searchModels = [
	'customer',
	'customerTeam',
	'event',
	'feature',
	'industry',
	'material',
	'metric',
	'persona',
	'project',
	'useCase',
	'tag',
	'training',
	// 'userEngagement',
] as const satisfies ModelName[]

const authModels = [
	'customer',
	'event',
	'feature',
	'link',
	'material',
	'materialType',
	'metric',
	'persona',
	'project',
	'useCaseType',
	'training',
	// 'userEngagement',
] as const satisfies ModelName[]
export type AuthModel = (typeof authModels)[number]

export const suggestionModels = [] as const satisfies ModelName[]

// TODO
// const modelsOnlyAdminsCanChange = [
// 	'industry',
// 	'useCaseType',
// 	'link',
// ] as const satisfies ModelName[]

const modelsWithTags = [
	'customer',
	'customerTeam',
	'event',
	'feature',
	'material',
	'materialType',
	'metric',
	'project',
	'useCase',
	'training',
	// 'userEngagement',
] satisfies ModelName[]

export const manyToManyRelations = [
	['customer', 'industry'],
	['customer', 'useCase'],
	// ['customer', 'userEngagement'],
	['useCase', 'useCaseType'],
	['customerTeam', 'useCase'],
	['material', 'materialType'],
	['material', 'persona'],
	['material', 'feature'],
	['event', 'feature'],
	['event', 'persona'],
	['training', 'persona'],
	['training', 'feature'],
	...getTagRelations(modelsWithTags),
] satisfies [ModelName, ModelName][]
